<template>
  <div>
    <v-layout pt-5  wrap justify-center>
      <v-flex xs10 sm10 md10 lg10 pl-5>
        <router-link to="allcourses" style="text-decoration: none; color: inherit;">
          <v-btn style="text-transform: capitalize;" color="primary" icon>
            <v-icon>mdi-arrow-left</v-icon> Go Back
          </v-btn>
        </router-link>
      </v-flex>
      
    </v-layout>
   
    <v-layout wrap justify-center pt-12  pb-12 :style="{
      background: 'linear-gradient( 356deg, #E1EEFF87 20%, #E1EEFF87 1%, #FFFFFF 35%, #FFFFFF 0%)',
    }">
    <v-flex xs10 pb-12 data-aos="zoom-in-up" data-aos-ease="ease"
    data-aos-duration="1500" data-aos-delay="500">
<v-layout wrap justify-center>

  
          <!-- Image on the left -->
          <!-- <v-flex xs2>
            <v-img src="./../../assets/images/about.png" width="200px" height="200px"></v-img>
          </v-flex> -->
          
          <!-- Content on the right -->
          <v-flex xs12 sm12 md6 lg8>
            <span style="font-family: OutfitBold; font-size: 20px; color: black">Advance Excel Course</span><br>
            <!-- Wrap the rating and span in a flex container -->
            <!-- <div style="display: flex; align-items: center;">
            
              <v-rating
                style="margin-left: -10px;"
                v-model="rating"
                background-color="orange lighten-3"
                color="orange"
                small
                half-increments
              ></v-rating>
             
              <span style="font-family: OutfitBold; font-size: 17px; color: black">4.5</span>&nbsp;&nbsp;<span style="font-family: JostRegular; font-size: 15px; color: grey">(73 Reviews)</span>
            </div> -->
        
            <!-- <v-layout wrap justify-center pt-3>
    
      <v-flex xs2 pb-5 >
        <v-btn class=" pa-4" color="#E6EFF9" style="border-radius: 8px; background-color: #E6EFF9 !important; color: black !important;" small>

  <v-icon small color="blue">mdi-clock-outline </v-icon>
  &nbsp; <span style="color: black; text-transform: capitalize;">7 Months</span>

</v-btn>

      </v-flex>

    
      <v-flex pb-5>
        <v-btn class=" pa-4" color="#E6EFF9" style="border-radius: 8px;" small >
         
            <v-icon small color="blue">mdi-book-open-outline </v-icon>
         &nbsp;<span small style="color: black; text-transform: capitalize;">27 Chapters</span>
    
        </v-btn>
      </v-flex>

      
    </v-layout> --><br>
<p style="font-family: JostRegular; color: black; font-size: 18px;text-align:justify">This course covers pivot tables, formulas, conditional formatting, Power Query, Power Pivot, and Power BI for data analysis. Participants gain skills in data manipulation, visualization, and reporting, enhancing decision-making abilities. Master Excel's advanced features for insightful data analysis and reporting.</p>
            <br>
            <!-- Analytics Section -->
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-layout wrap>
                  <v-flex  xs2 sm2 md2 lg1 pt-0>
                    <v-img width="20px" height="20px" src="./../../assets/icons/courseicon.png"></v-img>
                  </v-flex>
                  <v-flex  xs10 sm10 md10 lg11>
                    <span class="analyticsValue pt-8" style="
                      font-weight: bold;
                      font-size: 16px;
                      color: black;
                      font-family: 'OutfitBold';
                    ">
                      Comprehensive Curriculum
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              
              <v-flex xs12 sm6>
                <v-layout wrap>
                  <v-flex xs2 sm2 md2 lg1 pt-1>
                    <v-img width="20px" height="20px" src="./../../assets/icons/courseicon.png"></v-img>
                  </v-flex>
                  <v-flex xs10 sm10 md10 lg11>
                    <span class="analyticsValue " style="
                      font-weight: bold;
                      font-size: 16px;
                      color: black;
                      font-family: 'OutfitBold';
                    ">
                      Expert Instructors
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            
            <!-- Second line -->
            <v-layout wrap pt-lg-4>
              <v-flex xs12 sm6>
                <v-layout>
                  <v-flex xs2 sm2 md2 lg1 pt-1>
                    <v-img width="20px" height="20px" src="./../../assets/icons/courseicon.png"></v-img>
                  </v-flex>
                  <v-flex xs10 sm12 md10 lg11>
                    <span class="analyticsValue " style="
                      font-weight: bold;
                      font-size: 16px;
                      color: black;
                      font-family: 'OutfitBold';
                    ">
                      Feedback and Support 
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              
              <v-flex xs12 sm6>
                <v-layout wrap>
                  <v-flex xs2 sm2 md2 lg1 pt-1>
                    <v-img width="20px" height="20px" src="./../../assets/icons/courseicon.png"></v-img>
                  </v-flex>
                  <v-flex xs10 sm10 md10 lg11>
                    <span class="analyticsValue " style="
                      font-weight: bold;
                      font-size: 16px;
                      color: black;
                      font-family: 'OutfitBold';
                    ">
                      Career-ready Skills
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <br>
            <v-flex xs12>
      <v-btn class="px-5 pa-4 px-8" color="primary" small>
          <router-link to="" style="text-decoration: none">
              <span style="color: white; text-transform: capitalize;">Enroll Now</span>
          </router-link>
      </v-btn>
  </v-flex>

          </v-flex>
          
          <v-flex xs12 sm12 md6 lg4 pt-2 pl-lg-5>
            <v-img src="./../../assets/images/excel.png"></v-img>
          </v-flex>  
        </v-layout>
      </v-flex>
    </v-layout>

<!-- 



    <v-layout wrap justify-start px-12 pb-12>
      <v-flex xs6 px-12 pb-12>
<v-card rounded="lg" style="color: #1B6DC1;" class="pa-2">
                                <v-card-title>
                                  <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                                    'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                                    'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                                  }">
                                    Empowering Growth: Our Vision for a Prosperous <span
                                      style="color:#1A2421;">Future</span>
                                  </span>
                                </v-card-title>
                                <v-card-text style="text-align: justify;">
                                  <span style="font-family: JostRegular; color: #1A2421" :style="{
                                    'line-height': $vuetify.breakpoint.name == 'xs' ? '12px' : $vuetify.breakpoint.name == 'xl' ? '40px' : '15px',
                                    'font-size': $vuetify.breakpoint.name == 'xs' ? '15px' : $vuetify.breakpoint.name == 'xl' ? '25px' : '14px',
                                  }"> At <span style="color:#1B6DC1; font-weight: bold;">Kumarakom Ventures</span>,
                                    our overarching goal is to be a valuable contributor to the fastest-growing Indian
                                    economy by empowering our
                                    clients with knowledge and confidence, thus linking them to economically
                                    beneficial opportunities around the
                                    world. We strive to achieve this by providing innovative solutions, strategic
                                    insights, and personalized
                                    services tailored to the unique needs of each client. Through our dedication to
                                    excellence and commitment to
                                    fostering long-term partnerships, we aim to drive sustainable growth for our
                                    clients, our community, and our
                                    nation.
                                  </span>
                                </v-card-text>
                              </v-card>
      </v-flex>
    </v-layout> -->
    <v-layout wrap justify-start style="margin-top:-50px;">
    <v-flex xs12 sm12 md12 lg12>
      <Courseotherview />
    </v-flex>
  </v-layout>
  </div>
</template>

<script>
import Courseotherview from './courseotherview';

export default {
  components: {
    Courseotherview,
  },
  data() {
    return {
      rating: 4,
    };
  },
};
</script>
